import React from 'react';
import Modal from 'react-modal';
import logo from '../../assets/logo-paroquia-net-black.svg';
import currencyFormatBRL from '../../utils/currencyFormatBRL';

export default function ReceiptModal({ isOpen, handler, data, origem }) {
	const receiptTitle = origem === 48 ? 'Dizimista' : 'Ofertante';

	return (
		<Modal
			closeTimeoutMS={600}
			isOpen={isOpen}
			onRequestClose={handler}
			shouldCloseOnOverlayClick={true}
			overlayClassName={'fixed inset-0 bg-black/50 z-20'}
			className='after-open absolute inset-x-0 bottom-0 bg-transparent w-full rounded-t-3xl'>
			<section className='flex flex-col bg-white rounded rounded-t-3xl px-6 pt-6 pb-8'>
				<div className='flex items-center justify-between mb-3'>
					<div className='flex flex-1 flex-col'>
						<h1 className='text-sm font-semibold'>Recibo do {receiptTitle}</h1>
						<p className='text-sm mt-1'>Via Fiel</p>
					</div>
					<div className='flex flex-1 flex-col'>
						<img
							src={logo}
							className='object-contain w-24 flex self-end'
							alt='pnet-logo'
						/>
					</div>
				</div>
				<div className='flex items-end justify-between mb-2'>
					<div className='flex flex-1 flex-col'>
						<p className='text-sm'>{data.nomeParoquia}</p>
						<p className='text-xs'>{data.cnpjParoquia}</p>
						<p className='text-xs'>{`${data.usuarioNome} ${data.usuarioSobrenome}`}</p>
					</div>
					<div className='flex flex-col'>
						{origem === 48 && (
							<p className='text-xs'>{`ref. ${String(data?.mesReferencia).padStart(
								2,
								'0',
							)}/${data?.anoReferencia}`}</p>
						)}
					</div>
				</div>
				<div className='flex items-center justify-between mb-2'>
					<div className='flex flex-1 flex-row border-2 border-black py-2 px-5 items-center justify-between'>
						<p className='text-sm font-medium'>Crédito</p>
						<p className='text-sm font-medium'>{currencyFormatBRL(data.valor)}</p>
					</div>
				</div>
				<div className='flex items-start justify-between'>
					<div className='flex flex-1 flex-col'>
						<h3 className='text-sm'>
							{data.bandeira || 'Outro'} - ****
							{data.descricao?.slice(-4)}
						</h3>
						<p className='text-xs'>{`${data.usuarioNome} ${data.usuarioSobrenome}`}</p>
						<p className='text-xs'>
							{data.data} - {data.hora}
						</p>
					</div>
					<div className='flex flex-1 flex-col items-end'>
						<h3 className='text-sm'>{`ID ${data.lancamentoId}`}</h3>
						<p className='text-xs'> </p>
					</div>
				</div>
			</section>
		</Modal>
	);
}
