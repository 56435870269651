export default function currencyFormatBRL(valor){
  let number = "R$ 0,00"

  if (valor == null){
    return number;
  }

  const value = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  return value;
};
