import { useState } from 'react';

export const useCardFlagImage = () => {
  const [value, setValue] = useState();

  const setCardFlagImage = (flag) => {
    switch (flag) {
      case 1:
        setValue({
          nome: 'Visa',
          image: require('../assets/credit-cards/bandeiras/visa.png'),
          bandeira: 1,
        });
        break;
      case 2:
        setValue({
          nome: 'Mastercard',
          image: require('../assets/credit-cards/bandeiras/mastercard.png'),
          bandeira: 2,
        });
        break;
      case 3:
        setValue({
          nome: 'Amex',
          image: require('../assets/credit-cards/bandeiras/amex.png'),
          bandeira: 3,
        });
        break;
      case 4:
        setValue({
          nome: 'Diners',
          image: require('../assets/credit-cards/bandeiras/diners-club.png'),
          bandeira: 4,
        });
        break;
      case 5:
        setValue({
          nome: 'Elo',
          image: require('../assets/credit-cards/bandeiras/elo.png'),
          bandeira: 5,
        });
        break;
      case 9:
        setValue({
          nome: 'Hipercard',
          image: require('../assets/credit-cards/bandeiras/hipercard.png'),
          bandeira: 9,
        });
        break;
      case 10:
        setValue({
          nome: 'Hiper',
          image: require('../assets/credit-cards/bandeiras/hiper.png'),
          bandeira: 10,
        });
        break;
      default:
        setValue({
          nome: 'Outro',
          image: require('../assets/credit-cards/bandeiras/credit-card.png'),
          bandeira: 0,
        });
        break;
    }
  }
  return [value, setCardFlagImage];   
}

