import React, { useEffect, useState } from 'react';
import { FiFileText } from 'react-icons/fi';
import { useSearchParams } from 'react-router-dom';
import SuccessImg from '../../assets/success_img.png';
//components
import Header from '../../components/Header';
import ReceiptModal from '../../components/ReceiptModal';
// utils
import currencyFormatBRL from '../../utils/currencyFormatBRL';
import { removeTextComunidade } from '../Checkout';

export default function Success() {
	const [searchParams] = useSearchParams();
	const [payloadCartao, setPayloadCartao] = useState({});
	const [isOpenReceiptModal, setIsOpenReceiptModal] = useState(false);
	const [origem, setOrigem] = useState(null);

	const mapDataToPayload = (data) => ({
		data: data.data,
		hora: data.hora,
		nomeParoquia: data.nomeParoquia,
		valor: data.valor,
		bandeira: data.bandeira,
		cidadeUfParoquia: data.cidadeUfParoquia,
		cnpjParoquia: data.cnpjParoquia,
		descricao: data.descricao,
		lancamentoId: data.lancamentoId,
		usuarioNome: data.usuario.nome,
		usuarioSobrenome: data.usuario.sobrenome,
		mesReferencia: data.lancamento.mesReferencia,
		anoReferencia: data.lancamento.anoReferencia,
		comunidade: data.comunidade ?? ""
	});

	useEffect(() => {
		const cardLancamento = () => {
			const dataText = searchParams.get('data');
			const origem = searchParams.get('origem');
			setOrigem(Number(origem));

			const data = JSON.parse(dataText);

			const mappedPayloadSuccess = mapDataToPayload(data);

			setPayloadCartao(mappedPayloadSuccess);
		};

		cardLancamento();
	}, [searchParams]);

	const handleOpenReceiptModal = () => setIsOpenReceiptModal(true);

	const handleCloseReceiptModal = () => setIsOpenReceiptModal(false);

	return (
		<>
			<Header origem={origem} paroquia={payloadCartao.nomeParoquia} />
			<section className='w-screen flex flex-1 flex-col items-center justify-between py-12 px-4 rounded-t-2xl -mt-2 z-10 bg-white'>
				<div className='flex flex-1 items-center justify-between flex-col'>
					<div className='flex flex-col items-center justify-between'>
						<div className='flex flex-col items-center'>
							<div className='mb-3'>
								<img src={SuccessImg} alt='img-success' />
							</div>
							<p className='text-text_green text-xs font-medium mb-6'>
								Lançamento feito com sucesso
							</p>
							<p className='text-text_gray text-sm mb-2'>
								{payloadCartao.data}, {payloadCartao.hora}
							</p>
							<h1 className='text-text_blue_navy font-semibold text-base mb-4 text-center'>
								{payloadCartao.nomeParoquia}
							</h1>
							<h1 className='text-text_blue_navy font-semibold text-base mb-4 text-center'>
								Comunidade {removeTextComunidade(payloadCartao.comunidade)}
							</h1>
							<h1 className='text-text_blue_navy font-medium text-2xl mb-12'>
								{currencyFormatBRL(payloadCartao.valor)}
							</h1>
						</div>
						<button
							onClick={handleOpenReceiptModal}
							className='w-80 h-12 rounded text-sm text-text_blue font-semibold border border-blue-500 flex items-center justify-center'>
							<div className='mr-4'>
								<FiFileText size={20} />
							</div>
							Ver comprovante
						</button>
					</div>
				</div>
			</section>
			{/* RECEIPT */}
			<ReceiptModal
				isOpen={isOpenReceiptModal}
				handler={handleCloseReceiptModal}
				data={payloadCartao}
				origem={origem}
			/>
		</>
	);
}
