import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorImg from '../../assets/error_img.png';
//components
import Header from '../../components/Header';

export default function Error() {
	const [searchParams] = useSearchParams();
	const [origem, setOrigem] = useState(null);
	const [transactionErrors, setTransactionErrors] = useState([]);

	useEffect(() => {
		const searchParamOrigem = searchParams.get('origem');
		setOrigem(Number(searchParamOrigem));

		const searchParamTransactionErrors = searchParams.get('transactionErrors');
		setTransactionErrors(
			searchParamTransactionErrors ? searchParamTransactionErrors.split(',') : [],
		);
	}, [searchParams]);

	return (
		<>
			<Header origem={origem} />
			<section className='w-screen flex flex-1 flex-col items-center justify-between py-12 px-4 rounded-t-2xl -mt-4 z-10 bg-white'>
				<div className='flex flex-1 flex-col items-center justify-center'>
					<div className='mb-2'>
						<img src={ErrorImg} alt='error' />
					</div>
					<p className='text-text_red text-xs mb-1 text-center font-medium'>
						Não foi possível realizar o lançamento
						<br />
					</p>
					{transactionErrors.length ? (
						<div className='mb-4'>
							{transactionErrors.map((message, i) => (
								<p
									key={i}
									className='text-text_red text-xs  text-center font-medium'>
									{message}
								</p>
							))}
						</div>
					) : (
						<p className='text-text_red text-xs px-8 mb-4 text-center font-medium'>
							por favor volte ao paroquiaNet e tente novamente.
						</p>
					)}

					<p className='text-blue_navy mb-10 text-center text-xs font-medium'>
						Caso algum valor tenha sido debitado de seu cartão,
						<br />
						entre em contato com nosso{' '}
						<a
							className='text-text_blue underline'
							href='https://theos.com.br/contato/'>
							suporte.
						</a>
					</p>
				</div>
			</section>
		</>
	);
}
