import React from 'react';
import Modal from 'react-modal';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//pages
import Checkout from './pages/Checkout';
import Error from './pages/Erro';
import Success from './pages/Success';

Modal.setAppElement('#root');

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' element={<Checkout />} />
				<Route exact path='/success' element={<Success />} />
				<Route exact path='/error' element={<Error />} />
			</Routes>
		</BrowserRouter>
	);
}
