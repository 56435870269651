import imgHeader from '../../assets/devolucao-background@3x.png';

export default function Header({ origem, paroquia }) {
	const origemLabel = origem === 48 ? 'devolução de dízimo' : 'contribuição de oferta';

	return (
		<div className='h-20 relative flex flex-initial flex-col overflow-clip'>
			<img className='w-full' src={imgHeader} alt='header' />
			<div className='flex justify-center items-center absolute inset-0'>
				<h4 className='text-white font-semibold mb-4 uppercase font-medium'>{origemLabel}</h4>
			</div>
			<div className='flex justify-center items-center absolute inset-0 top-14'>
				<h4 className='text-white font-semibold mb-4 uppercase font-medium'>{paroquia}</h4>
			</div>
		</div>
	);
}
