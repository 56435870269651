import React, { useEffect, useState } from 'react';
import { FiAlertCircle, FiRefreshCw } from 'react-icons/fi';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
// components
import Header from '../../components/Header';
//services
import api from '../../shared/services/api';
// utils
import currencyFormatBRL from '../../utils/currencyFormatBRL';
import dateFormatBRL from '../../utils/dateFormatBRL';

import { JSEncrypt } from 'jsencrypt';
import { useCardFlagImage } from '../../hooks/useCardFlagImage';

const CARD_STATUS = {
	recebimentoIniciado: 1,
	aguardandoRecebimento: 2,
	recebimentoRealizado: 3,
	aguardandoCancelamento: 4,
	cancelamentoRealizado: 5,
	recebimentoInterrompido: 6,
	operacaoCancelada: 7,
	cancelamentoIniciado: 8,
	transacaoNaoEncontrada: 30,
	erro: 97,
	dadosInconsistentes: 98,
	naoDefinido: 99,
};

export const capitalizeFirstLetter = (txt) => {
	return txt.charAt(0).toUpperCase() + txt.slice(1);
};

export const removeTextComunidade = (txt) => {
	return txt && capitalizeFirstLetter(txt.toLowerCase().replace('comunidade', '').trim());
};

export default function Checkout() {
	const navigate = useNavigate();

	const [cardFlag, setCardFlag] = useCardFlagImage();

	const [loading, setLoading] = useState(false);
	const [loadingTransaction, setLoadingTransaction] = useState(false);
	const [success, setSuccess] = useState(false);

	const [payload, setPayload] = useState({});
	const [checkoutData, setCheckoutData] = useState({});

	const [dataLancamento, setDataLancamento] = useState();
	const [origem, setOrigem] = useState(null);
	const [isRecorrencia, setIsRecorrencia] = useState(false);

	const [token, setToken] = useState('');
	const [signature, setSignature] = useState('');
	const [uid, setUid] = useState('');

	const typeRequest = { 48: 'LancamentoDeDizimoPorCartao', 49: 'LancamentoDeOfertaPorCartao' };
	const typeTransaction = {
		48: () => createTitheTransactionForCard(payload),
		49: () => createOfferTransactionForCard(payload),
	};

	const mapResponseToPayload = (response) => ({
		RecorrenciaDia: response.recorrenciaDia,
		RecorrenciaMes: response.recorrenciaMes,
		RecorrenciaValor: response.recorrenciaValor,
		anoReferencia: response.anoReferencia,
		anoValidade: response.anoValidade,
		bairro: response.paroquia.endereco.bairro,
		bandeira: response.bandeira,
		cartaoId: response.cartaoId === 0 ? null : response.cartaoId,
		cep: response.paroquia.endereco.cep,
		cidade: response.paroquia.endereco.cidade,
		codigoSeguranca: response.codigoSeguranca,
		cpf: response.cpf,
		dataLancamento: response.dataLancamento,
		mascaraDescricao: response.maskedDescricao,
		descricao: response.descricao,
		email: response.usuario.email,
		estado: response.paroquia.endereco.estado,
		logradouro: response.paroquia.endereco.logradouro,
		mesReferencia: response.mesReferencia,
		mesValidade: response.mesValidade,
		nomePortador: response.nomePortador,
		nomeUsuario: response.usuario.nome,
		padrao: response.padrao,
		paroquiaId: response.paroquia.id,
		paroquiaNome: response.paroquia.nome,
		comunidade: response.paroquia.comunidade?.nome ?? '',
		tipoOferta: response.tipoOferta,
		tipoOfertaDescricao: response.tipoOfertaDescricao,
		usuarioId: response.usuario.id,
		valor: response.valor,
		isRecorrencia: response.isRecorrencia,
		anonimo: response.anonimo,
	});

	useEffect(() => {
		const obterCheckoutLancamento = async () => {
			try {
				setLoadingTransaction(true);
				const searchParamsString = window.location.search.replace('?', '');
				const searchParams = searchParamsString.split('&').reduce((result, param) => {
					const [key, value] = param.split('=');
					if (!key || !value) return result;
					result[key] = value;
					return result;
				}, {});

				const guidLancamento = searchParams['gYBHgHfhyt'];
				const paramToken = searchParams['erparjKaRE'];

				setToken(paramToken);
				setSignature(`${searchParams['BRLEdWZiDq']}==`);
				setUid(searchParams['lxLznqkfqL']);

				const bearerToken = `Bearer ${paramToken}`;
				const config = {
					headers: {
						Authorization: bearerToken,
					},
				};

				const obterCheckoutLancamentoApiUrl = `/ObterCheckOutLancamento?guidLancamento=${guidLancamento}`;

				const { data } = await api.get(obterCheckoutLancamentoApiUrl, config);

				const { success, data: response } = data;

				if (!success) {
					setSuccess(false);
					return;
				}

				const mappedReponse = mapResponseToPayload(response);

				setPayload(mappedReponse);

				setCheckoutData(response);
				setOrigem(response.origem);
				setDataLancamento(dateFormatBRL(response.dataLancamento));

				setIsRecorrencia(response.isRecorrencia);
				setCardFlag(response.bandeira);

				setSuccess(true);

				setLoadingTransaction(false);
			} catch (error) {
				setPayload({});

				setOrigem(null);

				setSuccess(false);

				setLoadingTransaction(false);
			}
		};

		obterCheckoutLancamento();
	}, []);

	const handleSave = async () => {
		try {
			setLoading(true);
			const config = {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			};

			const url = typeRequest[origem];
			const transaction = typeTransaction[origem]();

			const transactionWithKeyValidator = createTransactionWithKeyValidator(transaction);
			const encryptedTransaction = createEncryptedTransaction(transactionWithKeyValidator);

			const {
				dataLancamento,
				email,
				nomeUsuario,
				padrao,
				mesReferencia,
				anoReferencia,
				isRecorrencia,
			} = payload;

			const body = {
				dataLancamento,
				email,
				nomeUsuario,
				padrao,
				mesReferencia,
				anoReferencia,
				isRecorrencia,
				data: encryptedTransaction || '',
			};

			const { data: response } = await api.post(`${url}`, body, config);

			setLoading(false);
			if (!response.success) {
				navigateError();
			}

			if (response.data.cartaoStatus !== CARD_STATUS.recebimentoRealizado) {
				const cartaoStatusMensagemList = response.data.cartaoStatusMensagem.split('\n');

				navigate({
					pathname: '/error',
					search: `?origem=${origem}&cardStatusMessageList=${cartaoStatusMensagemList}`,
				});
				return;
			}

			const data = {
				...response.data,
				usuario: { ...checkoutData.usuario },
				lancamento: {
					mesReferencia: checkoutData.mesReferencia,
					anoReferencia: checkoutData.anoReferencia,
				},
				comunidade: checkoutData.paroquia?.comunidade?.nome
			};
			const dataString = JSON.stringify(data);

			navigate({
				pathname: '/success',
				search: `?data=${dataString}&origem=${origem}`,
			});
			return;
		} catch (error) {
			setLoading(false);
			navigateError();
		}
	};

	const navigateError = () => {
		navigate({
			pathname: '/error',
			search: `?origem=${origem}`,
		});
	};

	const createTransactionForCard = ({
		paroquiaId,
		usuarioId,
		valor,
		cartaoId,
		descricao,
		bandeira,
		mesValidade,
		anoValidade,
		cpf,
		codigoSeguranca,
		nomePortador,
	}) => ({
		paroquiaId,
		usuarioId,
		valor,
		cartaoId,
		descricao,
		bandeira,
		mesValidade,
		anoValidade,
		cpf,
		codigoSeguranca,
		nomePortador,
	});

	const createTitheTransactionForCard = (payload) => ({
		...createTransactionForCard(payload),
		RecorrenciaDia: payload.RecorrenciaDia,
		RecorrenciaMes: payload.RecorrenciaMes,
		RecorrenciaValor: payload.RecorrenciaValor,
	});

	const createOfferTransactionForCard = (payload) => ({
		...createTransactionForCard(payload),
		anonimo: payload.anonimo,
		tipoOferta: payload.tipoOferta,
	});

	const createTransactionWithKeyValidator = (transaction) => {
		return {
			...transaction,
			keyValidator: `${payload.usuarioId},${uid},${payload?.paroquiaId}`,
		};
	};

	const createEncryptedTransaction = (transaction) => {
		const encrypter = new JSEncrypt({
			default_key_size: 4096,
		});
		encrypter.setPublicKey(signature);
		const transactionString = JSON.stringify(transaction);
		return encrypter.encrypt(transactionString);
	};

	if (loadingTransaction) {
		return (
			<section className='w-full flex flex-1 flex-col items-center justify-center py-8 px-4 gap-y-8 rounded-t-2xl -mt-4 z-10 bg-white'>
				<FiRefreshCw className='animate-spin w-12 h-12 text-text_blue_navy' />
				<p className='text-text_blue_navy font-semibold'>Carregando dados...</p>
			</section>
		);
	}

	return (
		<>
			<Header origem={origem} paroquia={payload.paroquiaNome} />
			{/* SUCCESS */}
			{success && (
				<>
					<section className='flex flex-1 flex-col p-4 mt-4 rounded-t-2xl z-10 bg-white justify-between'>
						<div className='flex flex-col'>
							<h3
								className={`text-xs font-semibold text-blue_navy tracking-wide ${
									isRecorrencia ? 'mb-3' : 'mb-1'
								}`}>
								DADOS DO LANÇAMENTO
							</h3>
							<div className='w-full border border-border_color rounded-md relative p-4 flex flex-col gap-y-1 mb-4'>
								<p className='text-blue_navy text-sm'>
									Valor: {currencyFormatBRL(payload.valor)}
								</p>
								{origem === 48 && (
									<p className='text-blue_navy text-sm'>
										Mês referente:{' '}
										{`${String(payload.mesReferencia)?.padStart(2, '0')}/${
											payload.anoReferencia
										}`}
									</p>
								)}

								{origem === 48 ? null : (
									<p className='text-blue_navy text-sm'>
										Data do lançamento: {dataLancamento}
									</p>
								)}
								{origem === 48 ? null : (
									<p className='text-blue_navy text-sm'>
										Tipo da oferta: {payload.tipoOfertaDescricao ?? ''}
									</p>
								)}
							</div>
							<h3 className='text-xs font-semibold text-blue_navy mb-1 self-start tracking-wide'>
								PARÓQUIA SELECIONADA
							</h3>
							<div className='w-full border border-border_color rounded-md p-4 mb-4'>
								<h3 className='mb-1 text-blue_navy '>{payload.paroquiaNome}</h3>
								<p className='mb-1 text-blue_navy text-sm '>
									{`Comunidade ${removeTextComunidade(payload.comunidade ?? '')}`}
								</p>
								<p className='mb-1 text-blue_navy text-sm '>
									{`${payload.logradouro}, ${payload.bairro}`}
								</p>
								<p className='mb-1 text-blue_navy text-sm '>
									{`${payload.cidade} - ${payload.estado} - ${payload.cep}`}
								</p>
							</div>
							<h3 className='text-xs font-semibold text-blue_navy mb-1 self-start tracking-wide'>
								CARTÃO SELECIONADO
							</h3>
							<div className='w-full border border-border_color rounded-md flex items-center gap-x-4 p-4 mb-6'>
								<img src={cardFlag.image} className='w-12' alt='bandeira-cartão' />
								<div className='flex flex-col gap-y-1'>
									<h3 className='text-blue_navy font-semibold'>
										{payload.mascaraDescricao}
									</h3>
									<p className='text-blue_navy text-xs'>
										{`validade: ${payload.mesValidade}/${payload.anoValidade}`}
									</p>
								</div>
							</div>
						</div>
						<button
							onClick={handleSave}
							className='w-full h-12 rounded bg-background_button text-sm text-white font-bold'>
							Confirmar lançamento
						</button>
					</section>
				</>
			)}
			{/* ERROR */}
			{!success && (
				<>
					<section className='flex flex-1 flex-col justify-center py-8 px-4 rounded-t-2xl -mt-4 z-10 bg-white'>
						<div className='flex flex-1 flex-col items-center justify-center gap-y-4'>
							<FiAlertCircle className='w-12 h-12 text-text_red' />
							<h3 className='text-text_blue_navy text-center font-medium'>
								Ocorreu um erro ao recuperar o lançamento, volte ao paroquiaNet e
								tente novamente.
							</h3>
						</div>
					</section>
				</>
			)}
			{/* LOADING */}
			<Modal
				isOpen={loading}
				shouldCloseOnOverlayClick={false}
				overlayClassName={'fixed inset-0 bg-black/50 z-20'}
				className='after-open absolute inset-0 flex flex-1 justify-center items-center bg-transparent'>
				<section className='flex flex-col justify-center items-center bg-white p-6 rounded-lg gap-y-4'>
					<FiRefreshCw className='animate-spin w-8 h-8 text-text_blue_navy' />
					<p className='text-text_blue_navy'>Processando...</p>
				</section>
			</Modal>
		</>
	);
}
